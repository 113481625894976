.gradient {
    font-size: 3.5rem;
    background: #00FF00;
    background: linear-gradient(to left, #00FF00 0%, #00FFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.group {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns by default */
    gap: 1rem; /* Space between grid items */
}

@media (max-width: 1024px) {
    .group {
        grid-template-columns: 1fr; /* 1 column on smaller screens */
    }
}

.logo {
    font-size: 2rem; /* Adjust the size as needed */
    font-weight: bold; /* Optional: make the text bold */
    letter-spacing: 2px;
    color: var(--pico-primary);
}

.logo:hover {
    text-decoration: none;
    color: var(--pico-primary);
}

/* Style for the second ul to increase the gap between its items */
.nav-links li {
    margin-right: 15px; /* Adjust the value as needed for larger gaps */
}

/* Optional: if you want to remove the gap after the last item */
.nav-links li:last-child {
    margin-right: 0;
}