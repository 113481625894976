.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
}

.card {
    position: relative;
    height: 520px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    min-width: 300px;
}

.card:hover {
    transform: translateY(-5px);
}

/* Style the text that appears above the image */
.card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #ffffff;
    padding: 20px;
    text-align: left;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); /* Safari support */
}

.card-content h3 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
}

.card-content p {
    margin: 8px 0 0;
    color: #ffffff;
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr; /* One card per row on smaller screens */
    }
}